<template>
  <div>
    <h3 class="mb-1">Suas empresas</h3>
    <b-table :items="companies" :fields="fields" class="border" />
  </div>
</template>

<script>
import { BRow, BCol, BImg, BCard, BButton, BTable } from 'bootstrap-vue'

export default {
  name: 'Companies',
  components: {
    BRow,
    BCol,
    BImg,
    BCard,
    BTable,
    BButton,
  },
  data() {
    return {
      fields: [
        { key: 'cnpj', label: 'CNPJ', tdClass: 'td100px text-nowrap' },
        { key: 'nome', label: 'Empresa' },
      ],
      companies: [],
    }
  },
  async mounted() {
    await this.getEconomicGroupData()
  },
  methods: {
    async getEconomicGroupData() {
      try {
        const { data } = await this.$store.dispatch(
          'assignor/currentEconomicGroup',
          this.$route.params.id,
        )
        this.companies = data.data.cedentes
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style>
.td100px {
  width: 100px !important;
}
</style>
